import { ROUTES } from 'common/routes';
import { LinkButton } from 'ui/atoms/button';
import { Subscription } from '../types';
import { colorConfig } from './colorsConfig';
import {
  Currency,
  FeaturesList,
  PointsInfo,
  PreviousPriceLabel,
  PreviousPriceWrapper,
  Price,
  PriceTile,
  Period,
  ButtonWrapper,
  ButtonLabel,
  StartExploringButton,
  StyledSubscriptionItem,
  SubscriptionBottomContent,
  SubscriptionTitle,
  PriceRemark,
} from './styled';
import { ReactComponent as TeamIcon } from './team-icon.svg';

interface SubscriptionItemProps {
  subscription: Subscription;
}

export const SubscriptionItem = ({ subscription }: SubscriptionItemProps) => {
  const { label, price, area_quota, info, name, priceRemark: priceRemrk } = subscription;

  const features = info.split('\n').map((feature) => feature.slice(1));

  return (
    <StyledSubscriptionItem $bgColor={colorConfig[name].bgColor} color={colorConfig[name].color}>
      <PriceTile $bgColor={colorConfig[name].themeBgColor}>
        {priceRemrk && <PriceRemark>{priceRemrk}</PriceRemark>}
        {name === 'Enterprise Package' ? (
          <TeamIcon />
        ) : (
          <>
            {price.previous_amount && (
              <PreviousPriceWrapper>
                <PreviousPriceLabel>
                  promo
                  <br />
                  price!
                </PreviousPriceLabel>
                <span>
                  <Price oldPrice>
                    {price.symbol}
                    {price.previous_amount}
                  </Price>
                  <Currency transparent>{price.currency}</Currency>
                </span>
              </PreviousPriceWrapper>
            )}
            <p>
              <Price>
                {price.symbol}
                {price.current_amount}
              </Price>
              <Currency>{price.currency}</Currency>
              <Period>monthly</Period>
            </p>
          </>
        )}
      </PriceTile>
      <SubscriptionTitle>{label}</SubscriptionTitle>
      <FeaturesList>
        {features.map((feature) => (
          <li key={feature}>{feature}</li>
        ))}
      </FeaturesList>
      <SubscriptionBottomContent>
        <PointsInfo color={colorConfig[name].areaQuotaColor}>
          {area_quota ? `${area_quota} calculation points` : 'custom points for your needs'}{' '}
        </PointsInfo>
        {area_quota ? (
          <StartExploringButton size="SM" $bgColor={colorConfig[name].themeBgColor}>
            start exploring
          </StartExploringButton>
        ) : (
          <ButtonWrapper>
            <LinkButton to={ROUTES.contactUs} size="SM">
              <ButtonLabel>contact us</ButtonLabel>
            </LinkButton>
          </ButtonWrapper>
        )}
      </SubscriptionBottomContent>
    </StyledSubscriptionItem>
  );
};
